import React, { Fragment } from 'react'
import YouTube, { Options } from 'react-youtube'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            display: 'flex'
        },
        logo: {
            display: 'flex',
            width: '150px'
        },
        container: {
            position: 'relative',
            width: '100%',
            height: '0',
            paddingBottom: '56.25%'
        },
        player: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        },
        title: {
            margin: 0,
            display: 'block',
            color: theme.palette.text.primary,
            marginTop: theme.spacing(1),
            fontWeight: 500,
            lineHeight: 1.2,
            textDecoration: 'none'
        },
    })
)

export default function Tube({ youtubeId }) {

    const classes = useStyles();
    
    const opts: Options = {
        // height: '360',
        // width: '100%', // TODO: use JS to style dynamically
        // playerVars: { // https://developers.google.com/youtube/player_parameters
        //     autoplay: 1
        // }
    }

    const  _onReady = (event) => {
        // console.log('event: ', event.target)
    }

    return (
        <Fragment>
            <div className={classes.container}>
                <YouTube
                    className={classes.player}
                    videoId={youtubeId}
                    opts={opts}
                    onReady={_onReady}
                />
            </div>
        </Fragment>
    )

}