import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tube from '../../components/Tube/Tube';
import TubeLink from '../../components/Tube/TubeLink';
import TubeTitle from '../../components/Tube/TubeTitle';
import TubeImage from '../../components/Tube/TubeImage';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            flexGrow: 1
        },
        gridcontainer: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        bottomImageContainer: {
          width: '100%',
          height: 'auto',
          maxHeight: '100%',
          overflowY: 'hidden'
          // [theme.breakpoints.up('lg')]: {
          //   height: '216px !important'
          // },
          // [theme.breakpoints.up('md')]: {
          //   height: '175px'
          // }
        },
        homeLink: {
          textDecoration: 'none'
        },
        loading: {
          marginTop: theme.spacing(2),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },

        masonrygrid: {
          display: 'flex',
          marginLeft: -30, /* gutter size offset */
          width: 'auto'
        },
        masonrygridcolumn: {
          paddingLeft: 30,
          backgroundClip: 'padding-box',
          '&:> div': {
            marginBottom: 30
          }
        }
    })
);

export const GET_VIDEOS = gql`
  query GetVideos {
    videos {
      videoId
      createdAt
      title
      youtubeId
      youtubeImage
    }
  }
`;

const Home = () => {
    
    const classes = useStyles();

    const { data, loading } = useQuery(GET_VIDEOS);

    const loadingVideos = loading
      ? (<div className={classes.loading}><CircularProgress color="primary" /></div>)
      : null;

    let mainVideosEl;
    let remainderVideosEl;
    const videos = get(data, 'videos', null);
    if (videos) {
      const mainVideosArr = videos.slice(0,3);
      const remainderVideosArr = videos.slice(3);

      mainVideosEl = mainVideosArr.length
          ? (
              mainVideosArr.map((video, i) => (
                <Box p={2} m={2} key={i}>
                  <Tube youtubeId={video.youtubeId} />
                  <TubeLink title={video.title} videoId={video.videoId} />
                </Box>
              ))
            )
          : null;

      remainderVideosEl = remainderVideosArr.length
          ? (
              remainderVideosArr.map((video, i) => (
                <Box mb={2} key={i}>
                  <Link to={`/videos/${video.videoId}`} className={classes.homeLink}>
                    <div className={classes.bottomImageContainer}>
                      <TubeImage youtubeImage={video.youtubeImage} title={video.title} />
                    </div>
                    <TubeTitle small={true} title={video.title} />
                  </Link>
                </Box>
              ))
            )
          : null;
    }

    return (
        <div className={classes.root}>
          <Box m={2}>
            <Grid container className={classes.gridcontainer} spacing={3}>
              <Grid container justify="center">
                <Grid item xs={12} lg={8}>
                  {loadingVideos}
                  {mainVideosEl}
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} lg={8}>
                  <Box p={2} m={2}>
                  <Masonry
                      breakpointCols={{
                        default: 3,
                        1100: 3,
                        700: 2,
                        500: 1
                      }}
                      className={classes.masonrygrid}
                      columnClassName={classes.masonrygridcolumn}
                    >
                      {remainderVideosEl}
                    </Masonry>
                    </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
    )

}

export default Home;