import React from 'react';
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        title: {
            margin: 0,
            display: 'block',
            color: theme.palette.text.primary,
            marginTop: theme.spacing(1),
            fontWeight: 500,
            lineHeight: 1.2,
            textDecoration: 'none'
        },
    })
)

const TubeTitle = ({ title, small = false }) => {

    const classes = useStyles();

    return (
        <Typography
            className={classes.title}
            variant={small ? 'body1' : 'h6'}
        >
            {title}
        </Typography>
    )

}

export default TubeTitle;