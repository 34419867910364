import React from 'react';
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        title: {
            margin: 0,
            display: 'block',
            color: theme.palette.text.primary,
            marginTop: theme.spacing(1),
            fontWeight: 500,
            lineHeight: 1.2,
            textDecoration: 'none'
        },
    })
)

const TubeLink = ({ title, videoId, small = false }) => {

    const classes = useStyles();

    return (
        <Typography
            className={classes.title}
            variant={small ? "body2" : "h6"}
            component={Link}
            to={`/videos/${videoId}`}
        >
            {title}
        </Typography>
    )

}

export default TubeLink;