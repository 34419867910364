import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Box, Grid, FormControl, InputLabel, FilledInput, CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useHistory } from 'react-router-dom';
import Tube from '../../components/Tube/Tube';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import get from 'lodash/get';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center'
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    paper: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column'
    },
    formGroup: {
        display: 'flex'
    },
    button: {
        alignSelf: 'flex-end',
        position: 'relative'
    },
    icon: {
        verticalAlign: 'middle',
        fontSize: '3rem'
    },
    error: {
        color: theme.palette.primary.main
    },
    progressSpinner: {
            position: 'absolute'
    },
  }),
);

const ADD_VIDEO = gql`
    mutation AddVideo($title: String!, $youtubeId: String!, $youtubeImage: String!, $description: String!) {
        addVideo(title: $title, youtubeId: $youtubeId, youtubeImage: $youtubeImage, description: $description) {
            title
            description
            youtubeId
            createdAt
        }
    }
`;

const AddVideo = () => {

    const [title, setTitle] = useState<string>('');
    const [youtubeId, setYoutubeId] = useState<string>('');
    const [youtubeImage, setYoutubeImage] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [errors, setErrors] = useState({} as any);

    const history = useHistory();

    const [addVideo, { loading }] = useMutation(ADD_VIDEO, {
        onError: (err) => {
            // TODO: Use lodash
            const { graphQLErrors } = err;
            const errs = graphQLErrors[0].extensions;
            setErrors(errs);
        },
        onCompleted: () => {
            history.push('/');
        },
        variables: { title, youtubeId, youtubeImage, description }
    });

    const classes = useStyles();

    const onSubmit = async event => {
        event.preventDefault();
        setErrors({});
        const youtubeUrl = encodeURIComponent(`https://www.youtube.com/watch?v=${youtubeId}`);
        try {
            const res = await axios.get(`https://opengraph.io/api/1.1/site/${youtubeUrl}?app_id=0a20dd45-9a1d-4010-b9b5-646808207c23`);
            const openGraph = get(res.data, 'openGraph');
            if (openGraph) {
                setYoutubeImage(openGraph.image.url);
                addVideo();
            } else {
                throw Error('No image found');
            }
        } catch (err) {
            setErrors({upload: 'Error uploading youtube data'});
        }
    }

    return (
        <Grid container spacing={3}>

            <Grid item xs={`auto`} lg={2} xl={3}></Grid>

            <Grid item xs={12} lg={8} xl={6}>
                <Box m={2}>
                    <Paper onSubmit={onSubmit} component="form" className={classes.paper}>
                        <Typography
                            variant="h5"
                            color="primary"
                            align="center"
                        >
                            <YouTubeIcon className={classes.icon} />&nbsp;Upload YouTube Video
                        </Typography>
                        <Box my={2}>
                            <FormControl error={!!errors.title} fullWidth variant="filled">
                                <InputLabel>Title</InputLabel>
                                <FilledInput onChange={e => setTitle(e.target.value)} />
                                <FormHelperText color="primary">{errors.title}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box my={2}>
                            <FormControl error={!!errors.youtubeId} fullWidth variant="filled">
                                <InputLabel htmlFor="filled-adornment-amount">YouTube ID</InputLabel>
                                <FilledInput onChange={e => setYoutubeId(e.target.value)} />
                                <FormHelperText color="primary">{errors.youtubeId}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box my={2}>
                            <FormControl fullWidth>
                                <TextField
                                    error={!!errors.description}
                                    onChange={e => setDescription(e.target.value)}
                                    id="filled-multiline-static"
                                    label="Video Description"
                                    multiline
                                    rows="4"
                                    variant="filled"
                                    helperText={errors.description}
                                />
                            </FormControl>
                        </Box>
                        {
                            youtubeId.length === 11
                            && <Box my={2}><Tube youtubeId={youtubeId} /></Box>
                        }
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!title || !youtubeId || !description || loading}
                        >
                            { loading && <CircularProgress
                                            size={30}
                                            color="primary"
                                            className={classes.progressSpinner} />
                            }
                            Submit&nbsp;<ArrowRightIcon />
                        </Button>
                    </Paper>
                </Box>
            </Grid>

            <Grid item xs={`auto`} lg={2} xl={3}></Grid>

        </Grid>
    )
}

export default AddVideo;