import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Theme, createStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import logo from '../../hockeyclipz.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            display: 'flex'
        },
        logo: {
            display: 'flex',
            width: '150px'
        }
    })
)

export default function Header() {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h5"
                        noWrap
                    >
                        <Link to="/"><img src={logo} className={classes.logo} alt="hockey clipz dot com" /></Link>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    )
}