import React, { useEffect, Fragment } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Tube from '../../components/Tube/Tube';
import TubeTitle from '../../components/Tube/TubeTitle';
import TubeLink from '../../components/Tube/TubeLink';
import TubeImage from '../../components/Tube/TubeImage';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            flexGrow: 1
        },
        sideImageContainer: {
            width: '100%',
            height: 'auto'
        },
        loading: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
);

const GET_VIDEO = gql`
  query GetVideo($id: String!) {
    video(id: $id) {
      createdAt
      title
      youtubeId
    }
  }
`;

export const GET_VIDEOS = gql`
  query GetVideos {
    videos {
      videoId
      createdAt
      title
      youtubeId
      youtubeImage
    }
  }
`;

const Video = () => {

    const classes = useStyles();
    
    let { id } = useParams();

    useEffect(() => {
        window && window.scrollTo(0,0);
    }, [id]);

    const { data, loading, error } = useQuery(GET_VIDEO, {variables: { id } });
    const { data: videosData } = useQuery(GET_VIDEOS);

    const video = get(data, 'video', null);
    let videoEl;
    if (video) {
        videoEl = (<Box p={1}><Tube youtubeId={video.youtubeId} /><TubeTitle title={video.title} /></Box>)
    }

    const videos = get(videosData, 'videos', null);
    let videosEl;
    if (videos) {
        videosEl = videos.map((video, i) => (
            <Fragment key={i}>
                    <Grid item xs={6}>
                        <Box p={1}>
                            <Link to={`/videos/${video.videoId}`}>
                                <div className={classes.sideImageContainer}>
                                        <TubeImage youtubeImage={video.youtubeImage} title={video.title} />
                                </div>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <TubeLink title={video.title} videoId={video.videoId} small={true} />
                    </Grid>
            </Fragment>
        ));
    }

    const loadingVideo = loading
      ? (<div className={classes.loading}><CircularProgress color="primary" /></div>)
      : null;

    const errorVideo = error
        ? (<div>Ooops, no video found</div>)
        : null;

    return (
        <div className={classes.root}>
            <Box m={2}>
                <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                >
                    <Grid item xs={12} lg={8}>
                        {loadingVideo}
                        {errorVideo}
                        {!loading && videoEl}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container spacing={2} justify="flex-end">
                            {videosEl}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )

}

export default Video;