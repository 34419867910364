import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        image: {
            margin: 0,
            display: 'block',
            width: '100%',
            height: '100%'
        },
    })
)

const TubeImage = ({ youtubeImage, title }) => {

    const classes = useStyles();

    return (
        <img className={classes.image} src={youtubeImage} alt={title} />
    )

}

export default TubeImage;