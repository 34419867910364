import React from 'react'
import './App.css'
import Header from './components/Header/Header'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Video from './pages/Video/Video';
import AddVideo from './pages/AddVideo/AddVideo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: '64px'
    }
  }),
);

function App() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Router>
        <Header />
        <Switch>
          <Route path="/video/add" children={<AddVideo />} />
          <Route exact path="/videos/:id" children={<Video />} />
          <Route path="/" children={<Home />} />
        </Switch>
      </Router>
    </div>
  );

}

export default App;